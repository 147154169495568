<template lang="">
  <div>
    <div class="d-flex align-items-end justify-content-end my-5">
      <button
        class="btn btn-primary"
        v-b-modal.modal-1
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Kendi Paketini Oluştur
      </button>
    </div>
    
          
    
    <div class="container" style="margin-top: 50px">
      <div class="row flex-items-xs-middle flex-items-xs-center">
        <!-- Table #1  -->
        <div class="col-xs-12 col-lg-4 pricing" v-for="(i,key) in paket" :key="key">
          
          <div class="card text-xs-center">
            <div class="card-header">
              <div>
          </div>
              <h3 class="display-2">{{i.Fiyat}} {{ i.ParaBirimi }}<span class="period"> /Aylık</span></h3>
            </div>
            <div class="card-block">
              <h4 class="card-title">{{i.PaketAdi}}</h4>
              <ul class="list-group">
                <li class="list-group-item">{{ i.HerkeseGorunurMu }}</li>
                <li class="list-group-item">{{ i.OlusturmaZamani }}</li>
                <li class="list-group-item">{{ i.UpdateZamani }}</li>
                <li class="list-group-item">Lorem, ipsum dolor.</li>
              </ul>
              <a href="#" class="btn btn-primary mt-2">Satın Al</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <b-modal id="modal-1" title="Paketini Oluştur" v-model="modal1">
        <div class="form-group">
          <label for="paket-ismi">Paket İsmini Giriniz</label>
          <input type="text" class="form-control" />
        </div>

        <h5 class="my-5">
          <i class="fas fa-plus text-primary"></i> Paket İçeriğini Belirleyiniz
        </h5>
        <div class="d-flex align-items-end justify-content-between">
          <b-form-group>
            <b-form-checkbox-group
              class="my-5"
              v-model="selected"
              :options="options"
              switches
              stacked
            ></b-form-checkbox-group>
          </b-form-group>

        
        </div>
        <hr />
        <div class="d-flex justify-content-end">
          <h5>
            Toplam Fiyat:
            <mark style="background-color: #ffc436; padding: 7px">
              {{price}}
            </mark>
          </h5>
        </div>

        <template #modal-footer>
          <div>
            <b-button class="mt-3 mx-3" @click="modal1 = false">Kapat</b-button>
            <b-button class="mt-3" variant="success"
              >Ekle</b-button
            >
          </div>
        </template>
        
      </b-modal>
    </div>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      modal1: false,
      price: "4.000₺",
      selected: [], // Must be an array reference!
      options: [
        { text: "içerik 1", value: "1"},
        { text: "içerik 2", value: "2" },
        { text: "içerik 3 ", value: "3" },
        { text: "içerik 4", value: "4" },
      ],
      selected1: [], // Must be an array reference!
      options1: [
        { text: "içerik 5", value: "5" },
        { text: "içerik 6", value: "6" },
        { text: "içerik 7 ", value: "7" },
        { text: "içerik 8", value: "8" },
      ],
      selected2: [], // Must be an array reference!
      options2: [
        { text: "içerik 9", value: "9" },
        { text: "içerik 10", value: "10" },
        { text: "içerik 11 ", value: "11" },
        { text: "içerik 12", value: "12" },
      ],
      paket:[
        
      ]
    };

  },
  mounted(){
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true;

      this.items = [];
      this.totalItems = 0;
      this.loading = true;

      let queryLimit = this.list_length ? this.list_length : 10;
      let query = `paketler_list?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&token=${this.myUser}`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$ApiService
        .get(query)
        .then((res) => {
          if (res.status == 200) {
            this.paket = res.data;
            console.log(this.paket)
                      }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
      
  },
      computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #57e2b2;
$light-gray: #f8f8f8;
$main-font-color: #808080;
$main-font-weight: 300;
.row {
  min-height: 100vh;
}

// Tables
.card {
  border: 0;
  border-radius: 0px;
  -webkit-box-shadow: 0 3px 0px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 3px 0px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
  padding: 2.25rem 0;
  position: relative;
  will-change: transform;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 5px;
    background-color: $primary-color;
    transition: 0.5s;
  }

  &:hover {
    transform: scale(1.05);
    -webkit-box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.08);

    &:after {
      width: 100%;
    }
  }
  & .card-header {
    background-color: white;
    padding-left: 2rem;
    border-bottom: 0px;
  }
  & .card-title {
    margin-bottom: 1rem;
  }
  & .card-block {
    padding-top: 0;
  }
  & .list-group-item {
    border: 0px;
    padding: 0.25rem;
    color: $main-font-color;
    font-weight: $main-font-weight;
  }
}

// Price
.display-2 {
  font-size: 7rem;
  letter-spacing: -0.5rem;
  & .currency {
    font-size: 2.75rem;
    position: relative;
    font-weight: $main-font-weight + 100;
    top: -45px;
    letter-spacing: 0px;
  }
  & .period {
    font-size: 1rem;
    color: lighten($main-font-color, 20%);
    letter-spacing: 0px;
  }
}

// Buttons
.btn {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: $main-font-weight + 200;
  color: #fff;
  border-radius: 0;
  padding: 0.75rem 1.25rem;
  letter-spacing: 1px;
}

.btn-gradient {
  background-color: #f2f2f2;
  transition: background 0.3s ease-in-out;

  &:hover {
    color: white;
    background-color: $primary-color;
  }
}
</style>
